
import { Options, Vue } from "vue-class-component";
import { mapActions, mapState } from "vuex";
@Options({
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["isLogin", "userName"]),
  },
  methods: {
    goto(place: string) {
      switch (place) {
        case "首页":
          this.$router.push({ path: "/" });
          break;
        case "文章搜索":
          this.$router.push({ path: "/search" });
          break;
        case "新文章":
          this.$router.push({ path: "/article/create" });
          break;
        case "文章管理":
          this.$router.push({ path: "/article/manage" });
          break;
        case "登录":
          window.location.href =
            "http://sso.leoyiblog.cn/?redirect=www.leoyiblog.cn";
          // "https://sso.leoyiblog.cn/?redirect=localhost:8080";
          break;
        default:
          break;
      }
    },
    ...mapActions("user", ["logout"]),
  },
})
export default class NaviBar extends Vue {}
