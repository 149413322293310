
import { Options, Vue } from "vue-class-component";
import NaviBar from "@/components/NaviBar.vue";
import FooterBar from "@/components/FooterBar.vue";
@Options({
  components: {
    NaviBar,
    FooterBar,
  },
})
export default class App extends Vue {}
