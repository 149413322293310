import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "my-header" }
const _hoisted_3 = { class: "my-main" }
const _hoisted_4 = { class: "my-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navi_bar = _resolveComponent("navi-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_navi_bar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_footer_bar)
    ])
  ]))
}